import Axios from "axios";
import { serverUrl } from "./Apollo";
import crypto from "crypto";
export function saveUserData(data, token) {
  localStorage.setItem("_dentonslogin", JSON.stringify(data));
  localStorage.setItem("_dentonslogintoken", token);
}
export function getUserData() {
  let data = localStorage.getItem("_dentonslogin");
  try {
    return JSON.parse(data);
  } catch (error) {
    return null;
  }
}
export function encryptPassword(pass) {
  return crypto.createHash("sha1").update(pass);
}
export function getUserToken() {
  let token = localStorage.getItem("_dentonslogintoken");
  return token;
}
export async function authenticate(username, password) {
  return new Promise(async (res, rej) => {
    try {
      let resp = await Axios.post(serverUrl + "/auth/login", {
        username,
        password: password,
      });
      if (resp.status === 200) {
        if (resp.data.enabled === "Y") {
          let userdata = resp.data;
          let usertoken = resp.data.token;
          saveUserData(userdata, usertoken);

          res({ token: usertoken, userdata });
        } else {
          res(null);
        }
      } else {
        res(null);
      }
    } catch (error) {
      rej(error);
    }
  });
}
export async function isAuthenticated() {
  return getUserData() != null;
}
export async function reauth(token) {
  return new Promise(async (res, rej) => {
    try {
      const dToken = token ? token : getUserToken();
      let resp = await Axios.get(serverUrl + "/auth/verifytoken", {
        headers: {
          token: dToken,
        },
      });
      if (resp.status == 200) {
        // saveUserData(resp.data, resp.data.token);
        res(getUserToken());
      }
    } catch (error) {
      saveUserData(null, null);
      rej(error);
    }
  });
}
